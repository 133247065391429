import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
Vue.use(VueRouter)
const routes = [
  {
    path: '/404',
    component: () => import('@/views/404'),
  },
  {
    path: '/',
    redirect: '/login',
    component: Layout,
    children: [{
      path: '/login',
      name: 'login',
      component: () => import('@/views/login'),
      meta: { title: '首页' },
    },
    ]
  },
  {
    path: '/bft',
    component: Layout,
    children: [{
      path: '/bft/openAccount',
      name: 'openAccount',
      component: () => import('@/views/bft/openAccount'),
      meta: { title: '开户' },
    },
    {
      path: '/bft/updateCard',
      name: 'updateCard',
      component: () => import('@/views/bft/updateCard'),
      meta: { title: '改卡' },
    },
    {
      path: '/bft/withdraw',
      name: 'withdraw',
      component: () => import('@/views/bft/withdraw'),
      meta: { title: '提现' },
    },
    {
      path: '/bft/wdRecord',
      name: 'wdRecord',
      component: () => import('@/views/bft/wdRecord'),
      meta: { title: '提现记录' },
    },
    {
      path: '/payOrder/wxNative',
      name: 'wxNative',
      component: () => import('@/views/payOrder/wxNative'),
      meta: { title: '支付' },
    },
    {
      path: '/payOrder/wxJsapi',
      name: 'wxJsapi',
      component: () => import('@/views/payOrder/wxJsapi'),
      meta: { title: '支付' },
    },
    {
      path: '/payOrder/wxH5',
      name: 'wxH5',
      component: () => import('@/views/payOrder/wxH5'),
      meta: { title: '支付' },
    },
    ]
  },
  { path: '*', redirect: '/404', hidden: true }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// 路由前置守卫
router.beforeEach((to, from, next) => {
  const ua = navigator.userAgent.toLowerCase();
  // if (
  //   to.path !== "/404" && to.path == '/payOrder/wxJsapi' &&
  //   !(ua.match(/MicroMessenger/i) == "micromessenger")
  // ) {
  //   next("/404");
  // } else {
    next();
  // }
})
router.afterEach(() => {
  window.scrollTo(0, 0);
});
export default router
