/**
 * api 对象.存储所有的api地址
 */
const API = {
  // 查询申请开户状态
  getStatus: "/h5/bft/account/getStatus",
  // 个人开户
  openPerson: "/h5/bft/account/openPerson",

  // 查询卡信息
  getCardPerson: "/h5/bft/card/getCardPerson",

  // 个人改卡
  updatePersonCard: "/h5/bft/card/updatePersonCard",

  // 查询个人账户余额
  getBalPerson: "/h5/bft/withdraw/getBalPerson",

  // 查询卡信息
  withdrawCardPerson: "/h5/bft/withdraw/getCardPerson",

  // 查询状态
  withdrawStatus: "/h5/bft/withdraw/getStatus",

  // 提交
  submit: "/h5/bft/withdraw/submit",

  // 查询列表
  getList: "/h5/bft/withdrawRecord/getList",

  /*用户H5-支付-微信二维码支付 H 5 Pay Order Controller*/
  // 查询数据
  wxNative: "/h5/payOrder/wxNative/get",

  // 查询数据 用户H5-支付-微信JSAPI支付 H 5
  //  Pay Order Wx Jsapi Controller
  getWxJsapi: "/h5/payOrder/wxJsapi/get",
  // 用户H5-支付-微信H5支付
  getH5Pay: "/h5/payOrder/wxH5/get",
};

export default API;
